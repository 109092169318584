/**************  
VARIABLES
***************/
.cta-banner {
  position: absolute;
}

.home.section-one.grid-container.fluid {
  position: relative;
  margin-top: 12rem;
}
@media screen and (max-width: 39.9375em) {
  .home.section-one.grid-container.fluid {
    margin-top: 10rem;
  }
}
.home.section-one.grid-container.fluid .mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.45);
  z-index: 0;
}
.home.section-one.grid-container.fluid video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  -o-object-fit: cover;
  object-fit: cover;
  max-width: 100%;
}
.home.section-one.grid-container.fluid .left-bar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  position: relative;
  z-index: 1;
  text-align: center;
}
@media screen and (min-width: 40em) and (max-width: 63.9375em) {
  .home.section-one.grid-container.fluid .left-bar {
    display: block;
    text-align: center;
  }
}
@media screen and (max-width: 39.9375em) {
  .home.section-one.grid-container.fluid .left-bar {
    display: block;
    text-align: center;
  }
}
.home.section-one.grid-container.fluid .left-bar .social {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  position: relative;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  height: 50%;
  width: 3rem;
  text-align: center;
}
.home.section-one.grid-container.fluid .left-bar .social a {
  color: #fff;
  display: inline-block;
  margin-bottom: 2rem;
}
.home.section-one.grid-container.fluid .left-bar .social a:hover {
  color: #d9ff0a;
}
.home.section-one.grid-container.fluid .left-bar .arrow-down {
  height: 50%;
  position: relative;
  width: 3rem;
  text-align: center;
}
@media screen and (min-width: 40em) and (max-width: 63.9375em) {
  .home.section-one.grid-container.fluid .left-bar .arrow-down {
    width: 100%;
  }
}
@media screen and (max-width: 39.9375em) {
  .home.section-one.grid-container.fluid .left-bar .arrow-down {
    width: 100%;
  }
}
.home.section-one.grid-container.fluid .left-bar .arrow-down #arrow-down {
  position: absolute;
  bottom: 0;
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
  -webkit-transform-origin: top left;
  transform-origin: top left;
  color: #fff;
  display: inline-block;
  font-size: 1.1rem;
  text-transform: uppercase;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 20rem;
  -webkit-transition: 0.1s all ease-in-out;
  transition: 0.1s all ease-in-out;
}
@media screen and (min-width: 64em) {
  .home.section-one.grid-container.fluid .left-bar .arrow-down #arrow-down {
    bottom: 5rem;
  }
}
@media screen and (min-width: 40em) and (max-width: 63.9375em) {
  .home.section-one.grid-container.fluid .left-bar .arrow-down #arrow-down {
    left: 50%;
    -webkit-transform: rotate(-90deg) translateY(-50%);
    transform: rotate(-90deg) translateY(-50%);
    bottom: 0;
  }
}
@media screen and (max-width: 39.9375em) {
  .home.section-one.grid-container.fluid .left-bar .arrow-down #arrow-down {
    left: 50%;
    -webkit-transform: rotate(-90deg) translateY(-50%);
    transform: rotate(-90deg) translateY(-50%);
    bottom: -5rem;
  }
}
.home.section-one.grid-container.fluid .left-bar .arrow-down #arrow-down span {
  width: 7rem;
  background: #fff;
  height: 1px;
  display: inline-block;
  margin-right: 2rem;
  top: 1.4rem;
  position: relative;
}
@media screen and (max-width: 39.9375em) {
  .home.section-one.grid-container.fluid .left-bar .arrow-down #arrow-down span {
    width: 5rem;
  }
}
.home.section-one.grid-container.fluid .left-bar .arrow-down #arrow-down:hover {
  cursor: pointer;
  color: #d9ff0a;
}
.home.section-one.grid-container.fluid .txt {
  padding: 10rem 5rem;
  position: relative;
  min-height: 65vh;
  text-align: center;
  z-index: 1;
  position: relative;
}
@media screen and (min-width: 40em) and (max-width: 63.9375em) {
  .home.section-one.grid-container.fluid .txt {
    padding: 10rem 5rem 30rem;
  }
}
@media screen and (max-width: 39.9375em) {
  .home.section-one.grid-container.fluid .txt {
    padding: 10rem 0 15rem;
  }
}
.home.section-one.grid-container.fluid .txt p,
.home.section-one.grid-container.fluid .txt h1 {
  color: #fff;
}
.home.section-one.grid-container.fluid .txt p {
  text-transform: uppercase;
  font-size: 1.6rem;
  line-height: 1.4;
}
@media screen and (max-width: 39.9375em) {
  .home.section-one.grid-container.fluid .txt p {
    font-size: 1.4rem;
    line-height: 2rem;
  }
}
.home.section-one.grid-container.fluid .txt h1 {
  margin-top: 2rem;
}
.home.section-one.grid-container.fluid .txt .counter-section {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
@media screen and (max-width: 39.9375em) {
  .home.section-one.grid-container.fluid .txt .counter-section {
    display: block;
  }
}
.home.section-one.grid-container.fluid .txt .counter-section .text {
  color: #fff;
  font-weight: normal;
  margin: 0;
  font-size: 3rem;
  line-height: 1;
}
@media screen and (min-width: 40em) and (max-width: 63.9375em) {
  .home.section-one.grid-container.fluid .txt .counter-section .text {
    font-size: 2rem;
  }
}
@media screen and (max-width: 39.9375em) {
  .home.section-one.grid-container.fluid .txt .counter-section .text {
    display: block;
    font-size: 1.8rem;
  }
}
.home.section-one.grid-container.fluid .txt .counter-section .counters {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: relative;
  height: 3rem;
  overflow: hidden;
  text-align: center;
}
.home.section-one.grid-container.fluid .txt .counter-section .counters .counter {
  color: #fff;
  letter-spacing: 2px;
  line-height: 1;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 3rem;
  font-size: 3rem;
  margin: 0 1rem;
  font-weight: 400;
}
@media screen and (min-width: 40em) and (max-width: 63.9375em) {
  .home.section-one.grid-container.fluid .txt .counter-section .counters .counter {
    font-size: 2.5rem;
  }
}
@media screen and (max-width: 39.9375em) {
  .home.section-one.grid-container.fluid .txt .counter-section .counters .counter {
    font-size: 2rem;
  }
}
.home.section-one.grid-container.fluid .txt .counter-section .counters .counter > span {
  z-index: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 100%;
  -webkit-transition: -webkit-transform 2s ease;
  transition: -webkit-transform 2s ease;
  transition: transform 2s ease;
  transition: transform 2s ease, -webkit-transform 2s ease;
  -webkit-transform: translateY(0);
  transform: translateY(0);
  line-height: 1.2;
}
.home.section-one.grid-container.fluid .txt .counter-section .counters .counter > span span {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  height: 100%;
}
.home.section-one.grid-container.fluid .txt .counter-section .counters .counter:nth-child(1) > span {
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
}
.home.section-one.grid-container.fluid .txt .counter-section .counters .counter:nth-child(2) > span {
  -webkit-transition-delay: 0.375s;
  transition-delay: 0.375s;
}
.home.section-one.grid-container.fluid .txt .counter-section .counters .counter:nth-child(3) > span {
  -webkit-transition-delay: 0.75s;
  transition-delay: 0.75s;
}
.home.section-one.grid-container.fluid .txt .counter-section .counters .counter:nth-child(4) > span {
  -webkit-transition-delay: 1.125s;
  transition-delay: 1.125s;
}
.home.section-one.grid-container.fluid .txt .counter-section .counters .counter:nth-child(5) > span {
  -webkit-transition-delay: 1.5s;
  transition-delay: 1.5s;
}
.home.section-one.grid-container.fluid .txt .counter-section .counters .counter:nth-child(6) > span {
  -webkit-transition-delay: 1.875s;
  transition-delay: 1.875s;
}
.home.section-one.grid-container.fluid .txt .counter-section .counters .counter:nth-child(7) > span {
  -webkit-transition-delay: 2.25s;
  transition-delay: 2.25s;
}
.home.section-one.grid-container.fluid .txt .counter-section .counters .counter:nth-child(8) > span {
  -webkit-transition-delay: 2.625s;
  transition-delay: 2.625s;
}
.home.section-one.grid-container.fluid .txt .counter-section .counters .counter:nth-child(9) > span {
  -webkit-transition-delay: 3s;
  transition-delay: 3s;
}
.home.section-one.grid-container.fluid .txt .counter-section .counters .counter:nth-child(10) > span {
  -webkit-transition-delay: 3.375s;
  transition-delay: 3.375s;
}
.home.section-one.grid-container.fluid .txt .counter-section .counters .counter:nth-child(11) > span {
  -webkit-transition-delay: 3.75s;
  transition-delay: 3.75s;
}
.home.section-one.grid-container.fluid .txt .counter-section .counters .counter:nth-child(12) > span {
  -webkit-transition-delay: 4.125s;
  transition-delay: 4.125s;
}
.home.section-one.grid-container.fluid .txt .counter-section .counters .counter:nth-child(13) > span {
  -webkit-transition-delay: 4.5s;
  transition-delay: 4.5s;
}
.home.section-one.grid-container.fluid .txt .counter-section .counters .counter:nth-child(14) > span {
  -webkit-transition-delay: 4.875s;
  transition-delay: 4.875s;
}
.home.section-one.grid-container.fluid .txt .counter-section .counters .counter:nth-child(15) > span {
  -webkit-transition-delay: 5.25s;
  transition-delay: 5.25s;
}
.home.section-one.grid-container.fluid .txt .counter-section .counters .counter:nth-child(16) > span {
  -webkit-transition-delay: 5.625s;
  transition-delay: 5.625s;
}
.home.section-one.grid-container.fluid .txt .counter-section .counters .counter:nth-child(17) > span {
  -webkit-transition-delay: 6s;
  transition-delay: 6s;
}
.home.section-one.grid-container.fluid .txt .counter-section .counters .counter:nth-child(18) > span {
  -webkit-transition-delay: 6.375s;
  transition-delay: 6.375s;
}
.home.section-one.grid-container.fluid .txt .counter-section .counters .counter:nth-child(19) > span {
  -webkit-transition-delay: 6.75s;
  transition-delay: 6.75s;
}
.home.section-one.grid-container.fluid .txt .counter-section .counters .counter:nth-child(20) > span {
  -webkit-transition-delay: 7.125s;
  transition-delay: 7.125s;
}
.home.section-one.grid-container.fluid .txt .product-filter {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 2rem 2rem 3rem;
  width: 60rem;
  display: inline-block;
  margin: 5rem auto 0;
  background: rgba(190, 190, 190, 0.25);
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  border-radius: 1rem;
  max-width: 100%;
}
@media screen and (max-width: 39.9375em) {
  .home.section-one.grid-container.fluid .txt .product-filter {
    width: 100%;
  }
}
.home.section-one.grid-container.fluid .txt .product-filter .title {
  color: #fff;
  -webkit-text-decoration: uppercase;
  text-decoration: uppercase;
  font-size: 1.4rem;
  line-height: 1.4rem;
  text-align: center;
  margin-bottom: 1.5rem;
}
.home.section-one.grid-container.fluid .txt .product-filter select {
  width: 30%;
  height: 4rem;
  background-color: #fff;
  border: none;
  border-radius: 1rem;
  font-size: 1.4rem;
  line-height: 2rem;
  margin: 0;
  padding: 0.5rem 2rem 0.5rem 1rem;
}
@media screen and (max-width: 39.9375em) {
  .home.section-one.grid-container.fluid .txt .product-filter select {
    width: 100%;
    margin-bottom: 1rem;
  }
}
.home.section-one.grid-container.fluid .txt .product-filter select#select-merk .option.active {
  display: block;
}
.home.section-one.grid-container.fluid .txt .product-filter select#select-merk:disabled {
  display: block;
}
.home.section-one.grid-container.fluid .txt .product-filter .button1 {
  width: 20%;
  font-size: 1.2rem;
  line-height: 1.2rem;
  margin: 0;
  height: 4rem;
  padding: 1.6rem 1rem 1rem;
}
@media screen and (max-width: 39.9375em) {
  .home.section-one.grid-container.fluid .txt .product-filter .button1 {
    width: 100%;
  }
}
.home.section-one.grid-container.fluid .txt.fr .product-filter {
  width: 70rem;
}
@media screen and (max-width: 39.9375em) {
  .home.section-one.grid-container.fluid .txt.fr .product-filter {
    width: 100%;
  }
}
.home.section-one.grid-container.fluid .right-bar {
  text-align: right;
  position: relative;
  z-index: 1;
}
.home.section-one.grid-container.fluid .right-bar #service {
  display: inline-block;
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
  -webkit-transform-origin: center;
  transform-origin: center;
  width: 13rem;
  position: relative;
  right: -3rem;
  top: 2rem;
  text-decoration: none;
}
@media screen and (min-width: 64em) and (max-width: 74.9375em) {
  .home.section-one.grid-container.fluid .right-bar #service {
    right: 1rem;
    top: 1rem;
  }
}
.home.section-one.grid-container.fluid .right-bar #service svg {
  padding: 0.5rem;
  border-radius: 0.5rem;
  background: #d9ff0a;
  -webkit-transition: 0.1s all ease-in-out;
  transition: 0.1s all ease-in-out;
  width: 3rem;
  height: 3rem;
}
.home.section-one.grid-container.fluid .right-bar #service svg path {
  -webkit-transition: 0.1s all ease-in-out;
  transition: 0.1s all ease-in-out;
}
.home.section-one.grid-container.fluid .right-bar #service span {
  margin-left: 1rem;
  color: #fff;
  font-size: 1.1rem;
  line-height: 1.1rem;
  position: relative;
  top: -1.1rem;
  -webkit-transition: 0.1s all ease-in-out;
  transition: 0.1s all ease-in-out;
}
.home.section-one.grid-container.fluid .right-bar #service:hover {
  cursor: pointer;
}
.home.section-one.grid-container.fluid .right-bar #service:hover svg {
  background: #233d54;
}
.home.section-one.grid-container.fluid .right-bar #service:hover svg path {
  stroke: #d9ff0a;
}
.home.section-one.grid-container.fluid .right-bar #service:hover span {
  color: #d9ff0a;
}
.home.section-two.grid-container.fluid {
  background: #233d54;
  padding-top: 0;
  position: relative;
  z-index: 1;
}
@media screen and (min-width: 64em) {
  .home.section-two.grid-container.fluid {
    padding-bottom: 15rem;
  }
}
@media screen and (min-width: 64em) and (max-width: 74.9375em) {
  .home.section-two.grid-container.fluid {
    padding-bottom: 10rem;
  }
}
@media screen and (min-width: 40em) and (max-width: 63.9375em) {
  .home.section-two.grid-container.fluid {
    padding-bottom: 10rem;
  }
}
@media screen and (max-width: 39.9375em) {
  .home.section-two.grid-container.fluid {
    padding-bottom: 5rem;
  }
}
@media screen and (min-width: 64em) {
  .home.section-two.grid-container.fluid .item {
    padding: 2rem 3rem;
  }
}
@media screen and (min-width: 64em) and (max-width: 74.9375em) {
  .home.section-two.grid-container.fluid .item {
    padding: 2rem;
  }
}
@media screen and (min-width: 40em) and (max-width: 63.9375em) {
  .home.section-two.grid-container.fluid .item {
    padding: 0 0 5rem;
  }
}
@media screen and (max-width: 39.9375em) {
  .home.section-two.grid-container.fluid .item {
    padding: 0 0 5rem;
  }
}
.home.section-two.grid-container.fluid .item .image img {
  width: 100%;
  border-radius: 1rem;
  -webkit-box-shadow: 5px 5px 21px -10px rgba(0, 0, 0, 0.75);
  box-shadow: 5px 5px 21px -10px rgba(0, 0, 0, 0.75);
  -webkit-transition: 0.1s all ease-in-out;
  transition: 0.1s all ease-in-out;
}
.home.section-two.grid-container.fluid .item .image:hover img {
  -webkit-box-shadow: none;
  box-shadow: none;
}
@media screen and (min-width: 64em) {
  .home.section-two.grid-container.fluid .item:nth-child(1) {
    margin-top: -15rem;
  }
}
@media screen and (min-width: 40em) and (max-width: 63.9375em) {
  .home.section-two.grid-container.fluid .item:nth-child(1) {
    margin-top: -5rem;
  }
}
@media screen and (max-width: 39.9375em) {
  .home.section-two.grid-container.fluid .item:nth-child(1) {
    margin-top: -3rem;
  }
}
@media screen and (min-width: 64em) {
  .home.section-two.grid-container.fluid .item:nth-child(2) {
    margin-top: -10rem;
  }
}
@media screen and (min-width: 64em) {
  .home.section-two.grid-container.fluid .item:nth-child(3) {
    margin-top: -5rem;
  }
}
.home.section-two.grid-container.fluid .item .content {
  padding: 0 3rem;
  position: relative;
  z-index: 1;
  margin-top: -2rem;
}
@media screen and (min-width: 64em) and (max-width: 74.9375em) {
  .home.section-two.grid-container.fluid .item .content {
    padding: 0 2rem;
  }
}
@media screen and (min-width: 40em) and (max-width: 63.9375em) {
  .home.section-two.grid-container.fluid .item .content {
    margin-top: -3rem;
  }
}
@media screen and (max-width: 39.9375em) {
  .home.section-two.grid-container.fluid .item .content {
    margin-top: -2rem;
    padding: 0 2rem;
  }
}
.home.section-two.grid-container.fluid .item .content h1,
.home.section-two.grid-container.fluid .item .content h2,
.home.section-two.grid-container.fluid .item .content h3,
.home.section-two.grid-container.fluid .item .content h4,
.home.section-two.grid-container.fluid .item .content h5,
.home.section-two.grid-container.fluid .item .content a,
.home.section-two.grid-container.fluid .item .content li,
.home.section-two.grid-container.fluid .item .content p {
  color: #fff;
}
@media screen and (min-width: 64em) {
  .home.section-two.grid-container.fluid .item .content h2 {
    font-size: 4rem;
    line-height: 5rem;
  }
}
@media screen and (min-width: 64em) and (max-width: 74.9375em) {
  .home.section-two.grid-container.fluid .item .content h2 {
    font-size: 3rem;
    line-height: 4rem;
  }
}
@media screen and (max-width: 39.9375em) {
  .home.section-two.grid-container.fluid .item .content h2 {
    font-size: 3rem;
    line-height: 4rem;
  }
}
.home.section-two.grid-container.fluid .item .content p {
  font-weight: 100;
}
.home.section-two.grid-container.fluid .item .content .read-more {
  font-family: "Spartan", sans-serif;
  font-size: 1.8rem;
  margin-top: 1.8rem;
  display: inline-block;
}
.home.section-two.grid-container.fluid .item .content .read-more:hover {
  color: #d9ff0a;
}
.home.section-three.grid-container.fluid {
  background: #bebebe;
  padding-top: 0;
  position: relative;
  z-index: 1;
}
@media screen and (min-width: 64em) {
  .home.section-three.grid-container.fluid .item {
    padding: 2rem 3rem;
  }
}
@media screen and (min-width: 64em) and (max-width: 74.9375em) {
  .home.section-three.grid-container.fluid .item {
    padding: 2rem;
  }
}
@media screen and (min-width: 40em) and (max-width: 63.9375em) {
  .home.section-three.grid-container.fluid .item {
    padding: 0 0 5rem;
  }
}
@media screen and (max-width: 39.9375em) {
  .home.section-three.grid-container.fluid .item {
    padding: 0 0 5rem;
  }
}
.home.section-three.grid-container.fluid .item .image img {
  width: 100%;
  border-radius: 1rem;
  -webkit-box-shadow: 5px 5px 21px -10px rgba(0, 0, 0, 0.75);
  box-shadow: 5px 5px 21px -10px rgba(0, 0, 0, 0.75);
  -webkit-transition: 0.1s all ease-in-out;
  transition: 0.1s all ease-in-out;
}
.home.section-three.grid-container.fluid .item .image:hover img {
  -webkit-box-shadow: none;
  box-shadow: none;
}
@media screen and (min-width: 64em) {
  .home.section-three.grid-container.fluid .item:nth-child(1) {
    margin-top: -15rem;
  }
}
@media screen and (min-width: 40em) and (max-width: 63.9375em) {
  .home.section-three.grid-container.fluid .item:nth-child(1) {
    margin-top: -5rem;
  }
}
@media screen and (max-width: 39.9375em) {
  .home.section-three.grid-container.fluid .item:nth-child(1) {
    margin-top: -3rem;
  }
}
@media screen and (min-width: 64em) {
  .home.section-three.grid-container.fluid .item:nth-child(2) {
    margin-top: -10rem;
  }
}
@media screen and (min-width: 64em) {
  .home.section-three.grid-container.fluid .item:nth-child(3) {
    margin-top: -5rem;
  }
}
.home.section-three.grid-container.fluid .item .content {
  padding: 0 3rem;
  position: relative;
  z-index: 1;
  margin-top: -2rem;
}
@media screen and (min-width: 64em) and (max-width: 74.9375em) {
  .home.section-three.grid-container.fluid .item .content {
    padding: 0 2rem;
  }
}
@media screen and (min-width: 40em) and (max-width: 63.9375em) {
  .home.section-three.grid-container.fluid .item .content {
    margin-top: -3rem;
  }
}
@media screen and (max-width: 39.9375em) {
  .home.section-three.grid-container.fluid .item .content {
    margin-top: -2rem;
    padding: 0 2rem;
  }
}
.home.section-three.grid-container.fluid .item .content h2 {
  color: #d9ff0a;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.6);
}
@media screen and (min-width: 64em) {
  .home.section-three.grid-container.fluid .item .content h2 {
    font-size: 4rem;
    line-height: 5rem;
  }
}
@media screen and (min-width: 64em) and (max-width: 74.9375em) {
  .home.section-three.grid-container.fluid .item .content h2 {
    font-size: 3rem;
    line-height: 4rem;
  }
}
@media screen and (max-width: 39.9375em) {
  .home.section-three.grid-container.fluid .item .content h2 {
    font-size: 3rem;
    line-height: 4rem;
  }
}
.home.section-three.grid-container.fluid .item .content p {
  font-weight: 100;
}
.home.section-three.grid-container.fluid .item .content .read-more {
  font-family: "Spartan", sans-serif;
  font-size: 1.8rem;
  margin-top: 1.8rem;
  display: inline-block;
}
.home.section-three.grid-container.fluid .item .content .read-more:hover {
  color: #d9ff0a;
}
@media screen and (min-width: 64em) {
  .home.section-four.grid-container.fluid {
    padding-top: 15rem;
    padding-bottom: 15rem;
  }
}
@media screen and (min-width: 64em) and (max-width: 74.9375em) {
  .home.section-four.grid-container.fluid {
    padding-top: 10rem;
    padding-bottom: 10rem;
  }
}
@media screen and (min-width: 40em) and (max-width: 63.9375em) {
  .home.section-four.grid-container.fluid .img {
    margin-bottom: 5rem;
  }
}
@media screen and (max-width: 39.9375em) {
  .home.section-four.grid-container.fluid .img {
    margin-bottom: 3rem;
  }
}
.home.section-four.grid-container.fluid .img .logo {
  position: relative;
  z-index: 1;
  margin-top: 1rem;
}
@media screen and (min-width: 64em) {
  .home.section-four.grid-container.fluid .img .logo {
    padding-left: 10rem;
  }
}
@media screen and (min-width: 64em) and (max-width: 74.9375em) {
  .home.section-four.grid-container.fluid .img .logo {
    padding-left: 0;
  }
}
.home.section-four.grid-container.fluid .img .logo img {
  max-height: 30rem;
}
@media screen and (min-width: 64em) and (max-width: 74.9375em) {
  .home.section-four.grid-container.fluid .img .logo img {
    max-height: 20rem;
  }
}
.home.section-four.grid-container.fluid .img .image {
  position: relative;
  z-index: 0;
  margin-top: -3rem;
}
@media screen and (min-width: 64em) {
  .home.section-four.grid-container.fluid .img .image {
    padding-left: 20rem;
  }
}
@media screen and (min-width: 64em) and (max-width: 74.9375em) {
  .home.section-four.grid-container.fluid .img .image {
    padding-left: 10rem;
  }
}
.home.section-four.grid-container.fluid .img .image img {
  width: 100%;
  height: auto;
  border-radius: 1rem;
  -webkit-box-shadow: 5px 5px 21px -10px rgba(0, 0, 0, 0.75);
  box-shadow: 5px 5px 21px -10px rgba(0, 0, 0, 0.75);
}
@media screen and (min-width: 64em) {
  .home.section-four.grid-container.fluid .txt {
    padding: 10rem;
  }
}
@media screen and (min-width: 64em) and (max-width: 74.9375em) {
  .home.section-four.grid-container.fluid .txt {
    padding: 10rem 0 0 10rem;
  }
}
.home.section-four.grid-container.fluid .txt p {
  font-weight: 100;
}
.home.section-five.grid-container.fluid {
  padding: 0;
  background: #000;
  position: relative;
}
@media screen and (max-width: 39.9375em) {
  .home.section-five.grid-container.fluid {
    margin-top: 5rem;
  }
}
.home.section-five.grid-container.fluid .image-wrapper {
  max-width: 100%;
}
.home.section-five.grid-container.fluid .image-wrapper img {
  width: 100%;
  height: auto;
}
.home.section-five.grid-container.fluid .text-wrapper {
  padding: 0 10rem 20rem;
}
@media screen and (min-width: 40em) and (max-width: 63.9375em) {
  .home.section-five.grid-container.fluid .text-wrapper {
    padding: 5rem;
  }
}
@media screen and (max-width: 39.9375em) {
  .home.section-five.grid-container.fluid .text-wrapper {
    padding: 2rem;
  }
}
.home.section-five.grid-container.fluid .text-wrapper .image img {
  position: absolute;
  right: 0;
  bottom: 0;
  max-height: 60rem;
  width: auto;
}
@media screen and (min-width: 64em) and (max-width: 74.9375em) {
  .home.section-five.grid-container.fluid .text-wrapper .image img {
    max-height: 45rem;
  }
}
@media screen and (min-width: 40em) and (max-width: 63.9375em) {
  .home.section-five.grid-container.fluid .text-wrapper .image img {
    position: relative;
  }
}
@media screen and (max-width: 39.9375em) {
  .home.section-five.grid-container.fluid .text-wrapper .image img {
    position: relative;
  }
}
.home.section-five.grid-container.fluid .text-wrapper .txt {
  z-index: 1;
  position: relative;
}
@media screen and (min-width: 64em) {
  .home.section-five.grid-container.fluid .text-wrapper .txt {
    margin-top: -3rem;
  }
}
@media screen and (min-width: 40em) and (max-width: 63.9375em) {
  .home.section-five.grid-container.fluid .text-wrapper .txt {
    padding-top: 5rem;
  }
}
@media screen and (max-width: 39.9375em) {
  .home.section-five.grid-container.fluid .text-wrapper .txt {
    padding-top: 3rem;
  }
}
.home.section-five.grid-container.fluid .text-wrapper .txt h1,
.home.section-five.grid-container.fluid .text-wrapper .txt h2,
.home.section-five.grid-container.fluid .text-wrapper .txt h3,
.home.section-five.grid-container.fluid .text-wrapper .txt h4,
.home.section-five.grid-container.fluid .text-wrapper .txt h5,
.home.section-five.grid-container.fluid .text-wrapper .txt a,
.home.section-five.grid-container.fluid .text-wrapper .txt li,
.home.section-five.grid-container.fluid .text-wrapper .txt p {
  color: #fff;
}
.home.section-five.grid-container.fluid .text-wrapper .txt .title {
  margin-bottom: 5rem;
}
@media screen and (min-width: 64em) {
  .home.section-five.grid-container.fluid .text-wrapper .txt .content {
    padding-left: 10rem;
  }
}
@media screen and (min-width: 64em) and (max-width: 74.9375em) {
  .home.section-five.grid-container.fluid .text-wrapper .txt .content {
    padding-left: 5rem;
  }
}
.home.section-five.grid-container.fluid .text-wrapper .txt .content p,
.home.section-five.grid-container.fluid .text-wrapper .txt .content a {
  font-size: 3rem;
  line-height: 4rem;
}
@media screen and (max-width: 39.9375em) {
  .home.section-five.grid-container.fluid .text-wrapper .txt .content p,
  .home.section-five.grid-container.fluid .text-wrapper .txt .content a {
    font-size: 2rem;
    line-height: 3rem;
  }
}
.home.section-five.grid-container.fluid .text-wrapper .txt .content p strong,
.home.section-five.grid-container.fluid .text-wrapper .txt .content a strong {
  font-weight: normal;
  border-bottom: 2px solid #d9ff0a;
}
@media screen and (min-width: 64em) {
  .home.section-six.grid-container.fluid .one {
    padding: 5rem;
  }
}
@media screen and (min-width: 64em) {
  .home.section-six.grid-container.fluid .one .title {
    padding: 5rem;
    margin-bottom: 5rem;
  }
}
@media screen and (min-width: 40em) and (max-width: 63.9375em) {
  .home.section-six.grid-container.fluid .one .title {
    padding-bottom: 5rem;
    margin: 0;
  }
}
@media screen and (min-width: 64em) {
  .home.section-six.grid-container.fluid .two {
    padding-right: 5rem;
    margin-top: -10rem;
  }
}
.home.section-six.grid-container.fluid .two img {
  width: 100%;
  height: auto;
  border-radius: 1rem;
  -webkit-box-shadow: 5px 5px 21px -10px rgba(0, 0, 0, 0.75);
  box-shadow: 5px 5px 21px -10px rgba(0, 0, 0, 0.75);
}
@media screen and (min-width: 64em) {
  .home.section-six.grid-container.fluid .three {
    padding-left: 5rem;
  }
}
.home.section-six.grid-container.fluid .three img {
  width: 100%;
  height: auto;
  border-radius: 1rem;
  -webkit-box-shadow: 5px 5px 21px -10px rgba(0, 0, 0, 0.75);
  box-shadow: 5px 5px 21px -10px rgba(0, 0, 0, 0.75);
}
@media screen and (min-width: 64em) {
  .home.section-six.grid-container.fluid .four .content {
    padding: 5rem;
  }
}
@media screen and (min-width: 40em) and (max-width: 63.9375em) {
  .home.section-six.grid-container.fluid .four .content {
    padding: 5rem 0;
  }
}
@media screen and (max-width: 39.9375em) {
  .home.section-six.grid-container.fluid .four .content {
    padding: 2rem 0 5rem;
  }
}
.home.section-six.grid-container.fluid .four .content p {
  font-weight: 100;
}